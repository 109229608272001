// Migrated
<template lang="pug">
.d-print-none
  PrettyTabsNav.pretty-tabs-layout-column.pretty-tabs-layout-md-row(
    :tabs="navTabs"
    :locale-urls="localeURLs.authSlug"
    :is-member="true"
    @on-tab-change="onTabChange"
  )
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()
    const route = useRoute()

    return {
      route,
      localeURLs,
    }
  },

  emits: ['onTabChange'],

  computed: {
    ...mapState(useLocaleStore, [
      'isCharter',
      'localeURLs'
    ]),

    ...mapState(useUserStore, [
      'memberPageTab',
      'memberPageTabs',
      'isLoggedIn'
    ]),


    navTabs () {
      const memberLocaleUrls = this.localeURLs.authSlug
      const memberPagePath = this.localeURLs.auth

      if(!this.isLoggedIn) {
        const tabs = [
          {
            text: 'memberPageTabLogin',
            to: `${memberPagePath}${memberLocaleUrls.login}`,
            localeUrlKey: 'login',
            translationKey: 'memberPageTabLogin'
          },
        ]

        return tabs
      } else {
        const tabs = [
          {
            text: 'memberPageTabFavourites',
            to: `${memberPagePath}${memberLocaleUrls.favourites}`,
            localeUrlKey: 'favourites',
            translationKey: 'memberPageTabFavourites'
          },
          {
            text: 'memberPageTabMyBookings',
            to: `${memberPagePath}${memberLocaleUrls["my-bookings"]}`,
            localeUrlKey: 'my-bookings',
            translationKey: 'memberPageTabMyBookings'
          },
          {
            text: 'memberPageTabSettings',
            to: `${memberPagePath}${memberLocaleUrls.settings}`,
            localeUrlKey: 'settings',
            translationKey: 'memberPageTabSettings'
          }
        ]

        return tabs
      }
    }
  },

  methods: {
    onTabChange (newActiveTab) {
      this.$emit('onTabChange', newActiveTab)
    },
  }
})
</script>

<style lang="scss">
@import "@layers/web/assets/scss/modules/nav-tabs";
.nav-item.favourite--tab{
  .favourite-icon {
    background: transparent !important;
  }
}
</style>
